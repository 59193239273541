import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Book = ({ pageContext }) => {
  const book = pageContext.book
  return (
    <Layout>
      <div class="well">
        <h1>{book.name}</h1>
        <h3>{book.subtitle}</h3>
      </div>
      <div class="btn-toolbar">
        <div class="btn-group">
          <a
            href={`/books/${book.id}/tex`}
            data-bypass="true"
            target="_tex"
            class="btn-mini btn"
          >
            <i class="icon-circle-arrow-down"></i> .tex
          </a>
        </div>

        <div class="btn-group">
          <a
            href={`/books/${book.id}/bib`}
            data-bypass="true"
            target="_tex"
            class="btn-mini btn"
          >
            <i class="icon-circle-arrow-down"></i> .bib
          </a>
        </div>
      </div>

      <ol>
        {pageContext.book.sections.map(section => {
          return (
            <li>
              <a href={`/books/${book.id}/sections/${section.id}`}>
                {section.name}
              </a>

              <ol>
                {section.subsections &&
                  section.subsections.map(subsection => {
                    return (
                      <li>
                        <a
                          href={`/books/${book.id}/sections/${section.id}/subsections/${subsection.id}`}
                        >
                          {subsection.name}
                        </a>
                        <ol>
                          {subsection.contents &&
                            subsection.contents.map(content => {
                              return (
                                <li>
                                  <a
                                    href={`/books/${book.id}/sections/${section.id}/subsections/${subsection.id}/${content.id}`}
                                  >
                                    {content.name}
                                  </a>
                                </li>
                              )
                            })}
                        </ol>
                      </li>
                    )
                  })}
              </ol>
              <ol>
                {section.contents &&
                  section.contents.map(content => {
                    return (
                      <li>
                        <a
                          href={`/books/${book.id}/sections/${section.id}/${content.id}`}
                        >
                          {content.name}
                        </a>
                      </li>
                    )
                  })}
              </ol>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default Book
